<template>
  <div :class="device.desktop ? `home pa-6 grey lighten-4`:`home pa-0 grey lighten-4`">
    <v-row>
      <v-spacer v-if="device.desktop"></v-spacer>
      <v-col :cols="device.desktop ? 6 : 12">
        <v-card
          class="mx-auto"
          max-width="400"
        >
          <v-img
            class="white--text align-end"
            height="150px"
            src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
          >
            <v-card-title>GANTI KATA SANDI</v-card-title>
          </v-img>

          <v-card-text class="text--primary">
            <v-col cols="12">
              <v-text-field
                label="Kata Sandi Baru"
                :color="theme.color"
                v-model="record.password"
                type="password"
                outlined
                dense
                hide-details
              ></v-text-field>

            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Ulang Kata Sandi Baru"
                :color="theme.color"
                v-model="record.repassword"
                type="password"
                outlined
                dense
                hide-details
              ></v-text-field>

            </v-col>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :color="theme.color"
              text
              @click="postChangePassword"
              class="mr-2"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-spacer v-if="device.desktop"></v-spacer>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Home",
  data: () => ({
    num: 1,
    headers: [
      { text: "#", value: "num" },
      {
        text: "TINGKAT",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "KETERANGAN", value: "description" },
      { text: "OPSI", value: "id" },
    ],

    search: null,
  }),
  computed: {
    ...mapState(["theme", "http", "device", "record", "snackbar"]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/master-tingkat",
      pagination: false,
      key: "uuid",
      title: "GANTI KATA SANDI",
      subtitle: "Formulir Ganti Kata Sandi",
      breadcrumbs: [
        {
          text: "Ganti Kata Sandi",
          disabled: true,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: true,
        add: true,
        edit: true,
        delete: true,
        bulkdelete: false,
        print: false,
        export: false,
      },
    });
  },
  mounted() {},
  methods: {
    ...mapActions(["setPage"]),
    postChangePassword: async function () {
      try {
        if (this.record.password != this.record.repassword) {
          this.snackbar.color = "red";
          this.snackbar.text = "Opps kata sandi tidak sama";
          this.snackbar.state = true;
          return;
        }
        let {
          data: { status, message },
        } = await this.http.post("api-v2/chngpwd", this.record);

        if (!status) {
          this.snackbar.color = "red";
          this.snackbar.text = message;
          this.snackbar.state = true;
          return;
        }

        this.snackbar.color = "green";
        this.snackbar.text = message;
        this.snackbar.state = true;
        return;
      } catch (error) {}
    },
  },
};
</script>
