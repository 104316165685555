<template>

  <v-container
    fluid
    class="pt-0 grid-list-xl"
  >

    <v-row :class="device.desktop ? `pa-1`:  `pa-01`">
      <v-col class="stats-widget-v3">
        <v-card class="mx-auto my-10">
          <v-toolbar
            color="blue darken-3"
            dark
          >

            <v-toolbar-title>LEMBAR SOAL</v-toolbar-title>

            <v-spacer></v-spacer>
            <v-btn icon>
              <v-icon @click="fetchSoal">refresh<span class="material-icons"> </span></v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon @click="sheet = true">keyboard_alt<span class="material-icons"> </span></v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-title>{{ ujian.mapel }}</v-card-title>
          <v-row
            align="center"
            class="mx-0"
          >
            <div class="grey--text ml-4">Tanggal : {{ ujian.tanggal }}</div>
            <v-spacer></v-spacer>
            <div :class="device.mobile ? `grey--text ml-4` : `grey--text mr-4`">
              Waktu:
              <v-chip
                small
                color="green"
              >{{ ujian.jam_mulai }}</v-chip> s.d
              <v-chip
                color="red"
                small
              >{{ ujian.jam_selesai }}</v-chip>
            </div>
            <div :class="device.mobile ? `grey--text ml-4` : `grey--text mr-4`">
              Jam : <v-chip
                color="blue"
                small
              >{{ jamview }}</v-chip>
            </div>
            <div :class="device.mobile ? `grey--text ml-4` : `grey--text mr-4`">
              Sisa Waktu :
              <v-chip
                color="yellow"
                small
              >{{ timeleft }} Menit</v-chip>
            </div>
          </v-row>
          <hr class="mt-5" />
          <v-card-text>
            <div class="mb-3 subtitle-1">SOAL :</div>
            <v-row class="pa-4">
              <div class=" pt-2">
                <v-chip
                  color="grey"
                  class="white--text"
                >{{
                  soal.nomor
                }}</v-chip>
              </div>

              <v-col cols="10">
                <div
                  class="pa-0"
                  v-html="soal.soal"
                ></div>
              </v-col>

              <v-col cols="12">
                <img
                  v-show="soal.as_gambar"
                  :src="soal.gambar"
                  max-height="300"
                  max-width="500"
                  style="object-fit: fill"
                >
              </v-col>
            </v-row>
            <div class="text-center">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
                v-show="loading"
              ></v-progress-circular>
            </div>
            <div class="mb-4 subtitle-1">JAWABAN :</div>
            <v-row
              class="pa-4 left"
              v-if="soal.type == '1'"
            >
              <div
                :class="typeof soal.pilihans[0] !== 'undefined'?  `pa-2 pilihan-soal `: `pa-2 red--text font-weight-bold pilihan-soal`"
                @click="postJawaban('A')"
              >
                <v-chip
                  :color="soal.jawaban_siswa == 'A' ? `red` : `grey`"
                  class="white--text"
                >A</v-chip>
                {{ typeof soal.pilihans[0] !=='undefined'  ? soal.pilihans[0].jawaban : "Error"}}
              </div>
              <v-col>
                <img
                  v-show="typeof soal.pilihans[0]  !== 'undefined' ?  soal.pilihans[0].as_gambar:false"
                  :src="typeof soal.pilihans[0] ? soal.pilihans[0].gambar :''"
                  max-height="100"
                  max-width="250"
                  style="object-fit: fill"
                />
              </v-col>
            </v-row>
            <v-row
              class="pa-4"
              v-if="soal.type == '1'"
            >

              <div :class="typeof soal.pilihans[1] !== 'undefined'?  `pa-2 pilihan-soal`: `pa-2 red--text font-weight-bold pilihan-soal`">
                <v-chip
                  :color="soal.jawaban_siswa == 'B' ? `red` : `grey`"
                  class="white--text"
                  @click="postJawaban('B')"
                >B</v-chip>
                {{ typeof soal.pilihans[1] !== 'undefined' ? soal.pilihans[1].jawaban : 'error'  }}
              </div>
              <v-col>
                <img
                  v-show="typeof soal.pilihans[1]!=='undefined' ? soal.pilihans[1].as_gambar : false"
                  :src="typeof soal.pilihans[1] !== 'undefined' ? soal.pilihans[1].gambar : '' "
                  max-height="100"
                  max-width="250"
                  style="object-fit: fill"
                />
              </v-col>
            </v-row>
            <v-row
              class="pa-4"
              v-if="soal.type == '1'"
            >
              <div :class="typeof soal.pilihans[2] !== 'undefined'?  `pa-2 pilihan-soal`: `pa-2 red--text font-weight-bold pilihan-soal`">
                <v-chip
                  :color="soal.jawaban_siswa == 'C' ? `red` : `grey`"
                  class="white--text"
                  @click="postJawaban('C')"
                >C</v-chip>

                {{ typeof soal.pilihans[2] !== 'undefined'? soal.pilihans[2].jawaban : "Error"  }}
              </div>
              <v-col>
                <img
                  v-show="soal.pilihans[2] !== 'undefined'? soal.pilihans[2].as_gambar:false"
                  :src="typeof soal.pilihans[2]!== 'undefined'? soal.pilihans[2].gambar:''"
                  max-height="100"
                  max-width="250"
                  style="object-fit: fill"
                />
              </v-col>
            </v-row>
            <v-row
              class="pa-4"
              v-if="soal.type == '1'"
            >
              <div :class="typeof soal.pilihans[3] !== 'undefined'?  `pa-2 pilihan-soal`: `pa-2 red--text font-weight-bold pilihan-soal`">
                <v-chip
                  :color="soal.jawaban_siswa == 'D' ? `red` : `grey`"
                  class="white--text"
                  @click="postJawaban('D')"
                >D</v-chip>

                {{typeof soal.pilihans[3] !== 'undefined' ? soal.pilihans[3].jawaban : "Error" }}
              </div>
              <v-col>
                <img
                  v-show="typeof soal.pilihans[3] !== 'undefined'
                  ?
                  soal.pilihans[3].as_gambar
                  :
                  false"
                  :src="typeof soal.pilihans[3] !=='undefined' ? soal.pilihans[3].gambar : '' "
                  max-height="100"
                  max-width="250"
                  style="object-fit: fill"
                />
              </v-col>
            </v-row>
            <v-row
              class="pa-4"
              v-if="soal.type == '1'"
              @click="postJawaban('E')"
            >
              <div :class="typeof soal.pilihans[4] !== 'undefined'?  `pa-2 pilihan-soal`: `pa-2 red--text font-weight-bold pilihan-soal`">
                <v-chip
                  :color="soal.jawaban_siswa == 'E' ? `red` : `grey`"
                  class="white--text"
                >E</v-chip>

                {{typeof soal.pilihans[4] !== 'undefined' ? soal.pilihans[4].jawaban : "Error : Pilihan tidak ditemukan"}}
              </div>
              <v-col>
                <img
                  v-show="typeof soal.pilihans[4]!=='undefined' ? soal.pilihans[4].as_gambar:false"
                  :src="typeof soal.pilihans[4]!== 'undefined' ? soal.pilihans[4].gambar:''"
                  max-height="100"
                  max-width="250"
                  style="object-fit: fill;"
                />
              </v-col>
            </v-row>

            <v-row
              class="pa-4"
              v-if="soal.type == '2'"
            >
              <v-col cols="12">
                <v-textarea
                  label="Jawaban Essai"
                  outlined
                  hide-details
                  dense
                  v-model="essai.jawaban_text"
                >{{essai.jawaban_text}}</v-textarea>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Foto Jawaban"
                  append-outer-icon="attachment"
                  v-model="essai.jawaban_foto"
                  :color="theme.color"
                  outlined
                  hide-details
                  dense
                  @click:append-outer="uploadJawabanFoto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-btn
                  outlined
                  color="blue"
                  @click="postJawabanEssai"
                  :small="device.mobile"
                >
                  <v-icon>save</v-icon> Simpan Jawaban
                </v-btn>
              </v-col>
            </v-row>

          </v-card-text>
          <v-card-actions class="">
            <v-spacer> </v-spacer>
            <v-btn
              outlined
              color="red "
              v-show="nomor != 1"
              :small="device.mobile"
              @click="prev"
            >
              <v-icon>arrow_left</v-icon> Sebelumnya
            </v-btn>

            <v-btn
              color="green "
              v-show="nomor < ujian.jumlah"
              outlined
              @click="next"
              :small="device.mobile"
            >
              Berikutnya <v-icon>arrow_right</v-icon>
            </v-btn>
            <v-btn
              outlined
              color="blue "
              v-show="ujian.jumlah == nomor"
              @click="postConfirm"
              :small="device.mobile"
            >
              <v-icon>stop</v-icon> Selesai
            </v-btn>
          </v-card-actions>
          <div class="text-center">
            <v-overlay :value="loading"></v-overlay>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <div class="text-center">
      <v-bottom-sheet
        v-model="sheet"
        max-width="400"
        inset
      >
        <v-sheet class="text-center">
          <v-container
            fluid
            class="pt-0 grid-list-xl"
          >
            <div class="subtitle-1 text-h6 grey--text mb-3">
              LOMPAT KE SOAL :
            </div>
            <v-row>
              <v-col
                cols="3"
                v-for="item in ujian.jumlah"
                :key="item"
              >
                <v-chip
                  color="green"
                  class="text-center"
                  @click="fetchSoalTo(item)"
                >{{ item }}</v-chip>
              </v-col>
            </v-row>
            <v-btn
              class="mt-6"
              color="error"
              @click="sheet = !sheet"
            >
              Tutup
            </v-btn>
          </v-container>
        </v-sheet>
      </v-bottom-sheet>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "lembar-ujian",

  data: () => ({
    num: 1,
    headers: [
      { text: "#", value: "num" },
      {
        text: "TINGKAT",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "KETERANGAN", value: "description" },
      { text: "OPSI", value: "id" },
    ],

    search: null,

    token: null,

    ujian: {},

    soal: {},

    nomor: 1,
    sheet: false,
    loading_next: false,
    loading_prev: false,
    jamview: null,
    timeleft: null,
    loading: false,
    pilihans: [],
    tabFocus: true,

    essai: {
      jawaban_text: null,
      jabatans_foto: null,
    },
  }),
  computed: {
    ...mapState([
      "theme",
      "http",
      "device",
      "record",
      "records",
      "event",
      "snackbar",
    ]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api",
    });

    this.fetchInfoSoal();
    this.fetchSoal();
    //this.fetchTimeLeft();
    setInterval(this.jam, 1000);
    setInterval(this.setTimeLeft, 60000);
    this.detectFocusOut();
  },
  mounted() {
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  },
  beforeDestroy() {
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  },
  methods: {
    ...mapActions(["setPage", "fetchRecords", "assignFileBrowse", "signOut"]),

    fetchInfoSoal: async function () {
      let { data: data } = await this.http.post("api-v2/infosoal", {
        id: this.$route.params.ujian_id,
      });

      this.ujian = data;
    },
    fetchTimeLeft: async function () {
      let {
        data: { time_left },
      } = await this.http.post("api-v2/get-time-left", {
        ujian_id: this.$route.params.ujian_id,
      });

      this.timeleft = time_left;
    },
    setTimeLeft: async function () {
      try {
        let { data } = await this.http.post("api-v2/set-time-left", {
          ujian_id: this.$route.params.ujian_id,
        });
        this.timeleft = data;
      } catch (error) {}
    },
    fetchSoal: async function () {
      try {
        this.loading = true;
        let { data } = await this.http.post("api-v2/get-soal", {
          ujian_id: this.$route.params.ujian_id,
          nomor: this.nomor,
        });

        this.soal = data;

        this.pilihans = data.pilihans;

        if (Number(this.pilihans.length) < 5) {
          this.snackbar.color = "red";
          this.snackbar.text =
            "Pilihan soal kurang silahkan hubungi pengwas..! ";
          this.snackbar.state = true;
          this.loading = false;
        }

        this.essai.jawaban_text = data.jawaban_essai;
        this.essai.jawaban_foto = data.jawaban_foto;
        this.loading = false;
      } catch (error) {
        this.soal = {};
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    fetchSoalTo: async function (val) {
      try {
        this.loading = true;

        this.nomor = val;

        let { data } = await this.http.post("api-v2/get-soal", {
          ujian_id: this.$route.params.ujian_id,
          nomor: this.nomor,
        });

        this.soal = data;

        if (this.soal.pilihans.length < 5) {
          this.snackbar.color = "red";
          this.snackbar.message =
            "Pilihan Soal Kurang Silahkan Untuk Soal : " +
            this.soal.headers.soal;
          this.snackbar.state = true;
        }
        this.sheet = false;
      } catch (error) {
        this.soal = {};
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    next: function () {
      this.nomor = this.ujian.jumlah > this.nomor ? this.nomor + 1 : this.nomor;
      this.fetchSoal();
    },
    prev: function () {
      this.nomor = this.nomor > 1 ? this.nomor - 1 : this.nomor;
      this.fetchSoal();
    },

    postJawaban: async function (val) {
      //dibatasi
      this.loading = true;
      setTimeout(async () => {
        try {
          let {
            data: { status, message },
          } = await this.http.post("api-v2/store-jawaban", {
            id: this.soal.ujian_soal_id,
            type: this.soal.type,
            pilihan: val,
            jawaban_essai: null,
            jawaban_foto: null,
          });

          if (!status) {
            this.snackbar.color = "red";
            this.snackbar.text = message;
            this.snackbar.state = true;

            return;
          }

          this.fetchSoal();
        } catch (error) {
          this.loading = false;
        } finally {
          this.loading = false;
        }
      }, 500);
    },
    postJawabanEssai: async function () {
      try {
        this.loading = true;
        let {
          data: { status, message },
        } = await this.http.post("api-v2/store-jawaban", {
          id: this.soal.ujian_soal_id,
          type: this.soal.type,
          pilihan: null,
          jawaban_essai: this.essai.jawaban_text,
          jawaban_foto: this.essai.jawaban_foto,
        });

        if (!status) {
          this.snackbar.color = "red";
          this.snackbar.text = message;
          this.snackbar.state = true;

          return;
        }
        this.snackbar.color = "blue";
        this.snackbar.text = "Proses simpan jawaban berhasil";
        this.snackbar.state = true;

        this.fetchSoal();
      } catch (error) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    postConfirm: async function () {
      this.$router.push({
        name: "konfirmasi-selesai",
        params: { ujian_id: this.$route.params.ujian_id },
      });
    },

    jam: function () {
      var time = new Date();
      var hours = time.getHours();
      var minutes = time.getMinutes();
      var seconds = time.getSeconds();

      this.jamview = hours + ":" + minutes + ":" + seconds;
    },

    uploadJawabanFoto: function () {
      this.assignFileBrowse({
        fileType: [".jpg", ".jpeg", ".png"],
        query: {
          doctype: "jawaban",
        },
        callback: (response) => {
          setTimeout(() => {
            this.essai.jawaban_foto = response.name;
          }, 1000);
        },
      });
    },

    handleVisibilityChange() {
      if (document.hidden) {
      } else {
        // User came back to the page
        alert("Maaf Anda terdeteksi membuka tab baru pada aplikasi ini...!");
        this.signOut().then(() => {
          this.$router.push({ name: "login" }).then(() => {
            location.reload();
          });
        });
      }
    },
  },
  watch: {},
};
</script>

<style>
.pilihan-soal {
  align-self: center;
}
</style>
