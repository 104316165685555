<template>
  <div class="home pa-6">
    <v-flex
      row
      class="pb-2"
    >
      <v-icon>mdi-label</v-icon>
      <v-subheader>
        <h4>MANAJEMEN JADWAL UJIAN</h4>
      </v-subheader>
    </v-flex>
    <v-flex class="pb-5 pl-6">
      <div class="grey--text mb-2">
        Daftar Seluruh Soal Yang Tersedia
      </div>
    </v-flex>

    <v-row>
      <v-col col="12">
        <v-card>
          <v-card-title class="flex flex-row-reverse">
            <v-btn
              icon
              @click="openAddNew"
            >
              <v-icon color="blue">add_circle</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="fetchRecords"
            >
              <v-icon color="green">autorenew</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pencarian"
              single-line
              hide-details
              :color="theme"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="records"
            :items-per-page="10"
            class="elevation-2"
            :color="theme"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
          >
            <v-progress-linear
              slot="progress"
              :color="theme"
              height="1"
              indeterminate
            ></v-progress-linear>
            <template v-slot:item.status="{ value }">
              <v-icon
                small
                :color="value == true ? 'green' : 'red'"
                v-show="true"
              >calendar_today</v-icon>
            </template>
            <template v-slot:item.id="{ value }">
              <v-tooltip
                color="green"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    small
                    icon
                    v-on="on"
                  >
                    <v-icon
                      color="green"
                      @click="openRombel(value)"
                    >article</v-icon>
                  </v-btn>
                </template>
                <span>Kelola Rombel Yang Akan Mengikuti Ujian</span>
              </v-tooltip>
              <v-tooltip
                color="orange"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    small
                    icon
                    v-on="on"
                  >
                    <v-icon
                      color="orange"
                      @click="editRecord(value)"
                    >create</v-icon>
                  </v-btn>
                </template>
                <span>Edit Data</span>
              </v-tooltip>
              <v-tooltip
                color="red"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    small
                    icon
                    v-on="on"
                  >
                    <v-icon
                      color="red"
                      @click="postDeleteRecord(value)"
                    >delete</v-icon>
                  </v-btn>
                </template>
                <span>Hapus Data</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-dialog
        v-model="form.addnew"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="headline purple lighten-2">
            Formulir Jadwal Ujian
          </v-card-title>

          <v-card-text>
            <v-row :no-gutter="device.mobile">
              <v-col cols="12">
                <v-select
                  label="Periode"
                  :color="theme"
                  v-model="record.master_periode_id"
                  :hide-details="device.desktop"
                  :items="periodes"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Prog. Keahlian"
                  :color="theme"
                  v-model="record.jurusan_id"
                  :hide-details="device.desktop"
                  :items="jurusans"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Kelas/Tingkat"
                  :color="theme"
                  v-model="record.master_tingkat_id"
                  :hide-details="device.desktop"
                  :items="tingkats"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Mata Pelajaran"
                  :color="theme"
                  v-model="record.master_mapel_id"
                  :hide-details="device.desktop"
                  :items="mapels"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Semester"
                  :color="theme"
                  v-model="record.master_semester_id"
                  :hide-details="device.desktop"
                  :items="semesters"
                  @change="fetchKegiatans"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Kegiatan"
                  :color="theme"
                  v-model="record.master_kegiatan_id"
                  :hide-details="device.desktop"
                  :items="kegiatans"
                  @change="fetchSoals"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Soal"
                  :color="theme"
                  v-model="record.master_soal_id"
                  :hide-details="device.desktop"
                  :items="soals"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  label="Guru"
                  :color="theme"
                  v-model="record.master_pegawai_id"
                  :hide-details="device.desktop"
                  :items="pegawais"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Keterangan Tambahan"
                  :color="theme"
                  :hide-details="device.desktop"
                  v-model="record.title"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Jumlah Soal"
                  :color="theme"
                  :hide-details="device.desktop"
                  v-model="record.jumlah"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Bobot"
                  :color="theme"
                  :hide-details="device.desktop"
                  v-model="record.bobot"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Tanggal"
                  :color="theme"
                  :hide-details="device.desktop"
                  v-model="record.tanggal"
                  type="date"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Waktu"
                  :color="theme"
                  :hide-details="device.desktop"
                  v-model="record.waktu"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Jam Mul"
                  :color="theme"
                  :hide-details="device.desktop"
                  v-model="record.jam_mulai"
                  type="time"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Jam Sel"
                  :color="theme"
                  :hide-details="device.desktop"
                  v-model="record.jam_selesai"
                  type="time"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="TOKEN"
                  :color="theme"
                  :hide-details="device.desktop"
                  v-model="record.token"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-switch
                  label="Aktif"
                  :color="theme"
                  v-model="record.status"
                  :hide-details="device.desktop"
                ></v-switch>
              </v-col>
              <v-col cols="4">
                <v-switch
                  label="Random"
                  :color="theme"
                  v-model="record.as_random"
                  :hide-details="device.desktop"
                ></v-switch>
              </v-col>
              <v-col cols="4">
                <v-switch
                  label="Token"
                  :color="theme"
                  v-model="record.is_token"
                  :hide-details="device.desktop"
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              @click="closeAddNew"
            >
              Batal
            </v-btn>
            <v-btn
              color="blue dark-3"
              text
              @click="postAddNewRecord"
              v-show="!event.update"
            >TAMBAH</v-btn>
            <v-btn
              color="blue dark-3"
              text
              @click="postUpdateRecord"
              v-show="event.update"
            >UBAH</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Home",
  data: () => ({
    num: 1,
    headers: [
      { text: "#", value: "num" },
      {
        text: "UJIAN",
        align: "start",
        sortable: false,
        value: "ujian",
      },
      { text: "KELAS", value: "jurusan" },
      { text: "WAKTU", value: "waktu" },
      { text: "TOKEN", value: "token" },
      { text: "OPSI", value: "id" },
    ],

    search: null,
    form: {
      addnew: false,
      edit: false,
    },
    periodes: [],
    jurusans: [],
    tingkats: [],
    semesters: [],
    mapels: [],
    kegiatans: [],
    pegawais: [],
    soals: [],
    token: null,
  }),
  computed: {
    ...mapState([
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
    ]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/jadwal-ujian",
    });
    this.fetchPeriodes();
    this.fetchRecords();
    this.fetchJurusans();
    this.fetchTingkats();
    this.fetchMapels();
    this.fecthSemeters();
    this.fetchPegawais();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "postAddNew",
      "postUpdate",
      "postEdit",
      "fetchRecords",
      "postConfirmDelete",
    ]),
    openAddNew: function () {
      this.fecthToken().then(() => {
        this.form.addnew = true;
      });
    },
    closeAddNew: function () {
      this.event.update = false;
      this.form.addnew = false;
    },
    postAddNewRecord: function () {
      this.postAddNew(this.record).then(() => {
        this.fetchRecords();
        this.form.addnew = false;
      });
    },

    editRecord: function (val) {
      this.postEdit(val);
      this.form.addnew = true;
    },

    postUpdateRecord: function () {
      this.postUpdate(this.record).then(() => {
        this.fetchRecords();
        this.form.addnew = false;
      });
    },

    postDeleteRecord: function (val) {
      this.postConfirmDelete(val);
    },

    openRombel: function (val) {
      this.$router.push({ name: "rombel-ujian", params: { ujian_id: val } });
    },

    fetchPeriodes: async function () {
      let { data: data } = await this.http.post("api/master-periode-combo");
      this.periodes = data;
    },

    fetchJurusans: async function () {
      let { data: data } = await this.http.post("api/jurusan-combo");
      this.jurusans = data;
    },

    fetchTingkats: async function () {
      let { data: data } = await this.http.post("api/master-tingkat-combo");

      this.tingkats = data;
    },

    fetchMapels: async function () {
      let { data: data } = await this.http.post("api/master-mapel-combo");

      this.mapels = data;
    },

    fecthSemeters: async function () {
      let { data: data } = await this.http.post("api/master-semester-combo");

      this.semesters = data;
    },

    fetchKegiatans: async function () {
      let { data: data } = await this.http.post(
        "api/master-kegiatan-combo",
        this.record
      );

      this.kegiatans = data;
    },

    fetchSoals: async function () {
      let { data: data } = await this.http.post(
        "api/master-soal-combo",
        this.record
      );

      this.soals = data;
    },

    fetchPegawais: async function () {
      let { data: data } = await this.http.post("api/master-pegawai-combo");

      this.pegawais = data;
    },

    fecthToken: async function () {
      let { data } = await this.http.post("api/jadwal-ujian-token");

      this.token = data;
    },
  },
  watch: {
    "record.master_semester_id": function () {
      this.fetchKegiatans();
    },
    "record.master_kegiatan_id": function () {
      this.fetchSoals();
    },
    token: function () {
      this.record.token = this.token;
    },
  },
};
</script>
