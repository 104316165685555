import Vue from "vue";
import VueRouter from "vue-router";
import Auth from "../providers/AuthProviders";
import Login from "../views/auth/Login.vue";
import Register from "../views/auth/Register.vue";
import Forget from "../views/auth/Forget.vue";
import Home from "../views/backend/pages/Dashboard.vue";
import Base from "../views/backend/Base.vue";
import About from "../views/About.vue";
import User from "../views/backend/pages/User.vue";
import Changepwd from "../views/backend/pages/user/Changepwd.vue";
import ProfilPengguna from "../views/backend/pages/user/Profile.vue";
import BiodataSiswa from "../views/backend/pages/siswa/biodata/Index.vue";
import Dokumenku from "../views/backend/pages/siswa/document/Index.vue";
import Enroll from "../views/backend/pages/siswa/enrol/Index.vue";
import JadwalUjian from "../views/backend/pages/ujian/JadwalUjian.vue";
import RombelUjian from "../views/backend/pages/ujian/RombelUjian.vue";

//elarning
import KonfirmasiUjian from "../views/backend/pages/ujian/Konfirmasi.vue";
import LembarUjian from "../views/backend/pages/ujian/LembarUjian.vue";
import KonfirmasiSelesai from "../views/backend/pages/ujian/Selesai.vue";
import BuktiSelesai from "../views/backend/pages/ujian/BuktiSelesai.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "",
    meta: {
      auth: true,
    },
    component: Base,
    children: [
      { path: "", redirect: { name: "dashboard" } },
      { path: "dashboard", name: "dashboard", component: Home },

      { path: "about", name: "about", component: About },
      { path: "user", name: "user", component: User },
      {
        path: "chngpwd",
        name: "chngpwd",
        component: Changepwd,
      },
      {
        path: "profil-akun",
        name: "profil-akun",
        component: ProfilPengguna,
      },
      {
        path: "biodata-siswa",
        name: "biodata-siswa",
        component: BiodataSiswa,
      },
      {
        path: "siswa-dokumen-ku",
        name: "siswa-dokumen-ku",
        component: Dokumenku,
      },
      {
        path: "enroll",
        name: "enroll",
        component: Enroll,
      },

      //menu sims

      {
        path: "/jadwal-ujian",
        name: "jadwal-ujian",
        component: JadwalUjian,
      },
      {
        path: "/rombel-ujian/:ujian_id",
        name: "rombel-ujian",
        component: RombelUjian,
      },
      {
        path: "konfirmasi-ujian/:ujian_id",
        name: "konfirmasi-ujian",
        component: KonfirmasiUjian,
      },
      {
        path: "lembar-ujian/:ujian_id",
        name: "lembar-ujian",
        component: LembarUjian,
      },
      {
        path: "konfirmasi-selesai/:ujian_id",
        name: "konfirmasi-selesai",
        component: KonfirmasiSelesai,
      },
      {
        path: "bukti-ujian/:ujian_id",
        name: "bukti-ujian",
        component: BuktiSelesai,
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/forget",
    name: "forget",
    component: Forget,
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  mode: "history",
});

/**
 * router match
 */

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.auth);

  if (requiresAuth) {
    if (!Auth.check) {
      next({ name: "login" });
    } else {
      next();
    }
  } else {
    if (to.name === "login" && Auth.check) {
      next({ name: "dashboard" });
    } else {
      next();
    }
  }
});

export default router;
