<template>
  <div :class="device.desktop ? `home pa-6 grey lighten-4`:`home pa-0 grey lighten-4`">

    <v-row>
      <v-col cols="12">
        <v-row class="justify-center">

          <v-card class="animated animate__fadeInUp rounded-0 mt-10">
            <v-card-title class="white--text indigo lighten-2">Registrasi Kelas</v-card-title>

            <v-card-text>
              <v-row class="justify-center mt-5">
                <h4>KODE KELAS</h4>
                <v-col :cols="device.desktop ? `10` : `12`">
                  <v-row justify="center">
                    <v-col cols="12">
                      <v-text-field
                        outlined
                        :color="theme.color"
                        dense
                        v-model="record.kode_kelas"
                        :hide-details="device.desktop"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="mt-5">
              <v-col
                class="text-right"
                cols="12"
              >
                <v-btn
                  outlined
                  :color="theme.color"
                  @click="postEnroll"
                >REGISTRASI</v-btn>
              </v-col>
            </v-card-actions>
            <v-col>
              <div class="font-italic text-sm-body-2 grey--text ">

                *Untuk kode kelas silahkan minta ke wali kelas <br> anda (hanya untuk kelas 11,12,13)
              </div>
            </v-col>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "perusahaan-index",
  data: () => ({
    foto: "/",
    record: {},
  }),
  computed: {
    ...mapState([
      "theme",
      "http",
      "device",
      "records",
      "loading",
      "event",
      "snackbar",
    ]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api-v2/enroll",
      pagination: false,
      key: "uuid",
      title: "REGISTRASI KELAS",
      subtitle: "Formulir Registrasi Kelas",
      breadcrumbs: [
        {
          text: "Registrasi Kelas",
          disabled: true,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: true,
        add: true,
        edit: true,
        delete: true,
        bulkdelete: false,
        print: false,
        export: false,
      },
    });
  },
  mounted() {},
  methods: {
    ...mapActions(["setPage", "assignFileBrowse", "getUserInfo"]),

    postEnroll: async function () {
      try {
        let {
          data: { status, message },
        } = await this.http.post("api-v2/enroll", this.record);

        if (!status) {
          this.snackbar.color = "red";
          this.snackbar.text = message;
          this.snackbar.state = true;
          return;
        }

        this.snackbar.color = this.theme;
        this.snackbar.text = message;
        this.snackbar.state = true;
        this.$router.push({ name: "dashboard" });
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = "Opps..., terjadi kesalahan " + error;
        this.snackbar.state = true;
      } finally {
      }
    },
  },
};
</script>

<style></style>
