<template>
  <div class="home pa-6">
    <v-flex row class="pb-2">
      <v-icon>mdi-label</v-icon>
      <v-subheader> <h4>UJIAN - ROMBEL</h4> </v-subheader>
    </v-flex>
    <v-flex class="pb-5 pl-6">
      <div class="grey--text mb-2">
        Daftar Seluruh Rombel yang mengikuti ujian
      </div>
    </v-flex>

    <v-row>
      <v-col col="6">
        <v-card>
          <v-card-title class="flex flex-row-reverse">
            <v-btn icon @click="fetchRecords"
              ><v-icon color="green">autorenew</v-icon></v-btn
            >
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pencarian"
              single-line
              hide-details
              :color="theme"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="records"
            :items-per-page="10"
            class="elevation-2"
            :color="theme"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
          >
            <v-progress-linear
              slot="progress"
              :color="theme"
              height="1"
              indeterminate
            ></v-progress-linear>
            <template v-slot:item.id="{ value }">
              <v-tooltip color="red" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn text small icon v-on="on">
                    <v-icon color="red" @click="postDeleteRecord(value)"
                      >delete</v-icon
                    >
                  </v-btn>
                </template>

                <span>Hapus Data</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col col="6">
        <v-card>
          <v-card-title>
            Formulir Rombel
          </v-card-title>
          <v-card-subtitle>
            Formulir untuk mendaftarkan/merubah rombel
            <v-divider></v-divider>
          </v-card-subtitle>

          <v-card-text>
            <v-row :no-gutters="device.mobile">
              <v-col cols="12">
                <v-select
                  label="Prog.Keahlian"
                  :color="theme"
                  v-model="record.jurusan_id"
                  :hide-details="device.desktop"
                  :items="jurusans"
                  @change="fetchRombels"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Rombel"
                  :color="theme"
                  v-model="record.master_kelas_id"
                  :hide-details="device.desktop"
                  :items="rombels"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="flex flex-row-reverse">
            <v-btn
              color="blue dark-3"
              @click="postAddNewRecord"
              v-show="!event.update"
              outlined
              >TAMBAH
            </v-btn>
            <v-btn
              color="red dark-3"
              @click="postUpdateRecord"
              outlined
              v-show="event.update"
              >UBAH <v-icon>save</v-icon></v-btn
            >
            <v-spacer></v-spacer>

            <v-btn color="grey dark-3" @click="$router.go(-1)" outlined
              >BATAL</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Home",
  data: () => ({
    num: 1,
    headers: [
      { text: "#", value: "num" },
      {
        text: "PROG.KEAHLIAN",
        align: "start",
        sortable: false,
        value: "jurusan",
      },
      { text: "ROMBE/KELAS", value: "kelas" },
      { text: "OPSI", value: "id" },
    ],

    search: null,

    jurusans: [],

    rombels: [],
  }),
  computed: {
    ...mapState([
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
    ]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/rombel-ujian/" + this.$route.params.ujian_id,
    });
    this.fetchRecords();
    this.fetchJurusans();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "fetchRecords",
      "postAddNew",
      "postEdit",
      "postUpdate",
      "postConfirmDelete",
    ]),
    postAddNewRecord: function() {
      this.record.ujian_id = this.$route.params.ujian_id;
      this.postAddNew(this.record).then(() => {
        this.fetchRecords();
      });
    },
    editRecord: function(val) {
      this.postEdit(val);
    },

    postUpdateRecord: function() {
      this.postUpdate(this.record).then(() => {
        this.fetchRecords();
      });
    },

    postDeleteRecord: function(val) {
      this.postConfirmDelete(val);
    },

    fetchJurusans: async function() {
      let { data: data } = await this.http.post("api/jurusan-combo");

      this.jurusans = data;
    },

    fetchRombels: async function() {
      let { data: data } = await this.http.post(
        "api/master-kelas-combo-by-jurusan",
        this.record
      );

      this.rombels = data;
    },
  },
  watch: {
    "record.jurusan_id": function() {
      this.fetchRombels();
    },
  },
};
</script>
