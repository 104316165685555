<template>
  <v-container
    fluid
    class="pt-10 grid-list-xl"
  >

    <v-row>
      <div class="ml-5 mb-10 font-weight-bold grey--text">
        Selamat Datang, {{ user.username }}
        <div class="font-weight-regular grey--text">
          Inilah dashboard aplikasi anda
        </div>
      </div>
    </v-row>

    <v-row v-show="!isCompeleted">
      <v-col cols="12">
        <v-alert type="error">
          Perhatian...!, anda belum melengkapi biodata anda. Silahkan anda lengkapi terlebih dahulu untuk dapat menggunakan aplikasi ini
        </v-alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="stats-widget-v3">
        <v-card
          class="mx-auto"
          max-width="500"
        >
          <v-toolbar
            color="blue darken-3"
            dark
          >
            <v-app-bar-nav-icon></v-app-bar-nav-icon>

            <v-toolbar-title>IDENTITAS</v-toolbar-title>

            <v-spacer></v-spacer>
          </v-toolbar>

          <v-card
            class="mx-auto overflow-y-auto"
            height="300"
            max-width="500"
          >
            <v-row class="justify-center pt-6">
              <center>
                <v-avatar>
                  <img
                    :src="infosiswa.avatar"
                    alt="Foto"
                  />
                </v-avatar>
              </center>
            </v-row>
            <v-row class=" pt-5  pb-5 justify-center">
              <h5>{{ infosiswa.nama }}</h5>
            </v-row>
            <v-divider></v-divider>
            <table class="pa-2">
              <tr>
                <td>NIS</td>
                <td>&nbsp;:&nbsp;</td>
                <td>{{ infosiswa.nis }}</td>
              </tr>
              <tr>
                <td>NISN</td>
                <td>&nbsp;:&nbsp;</td>
                <td>{{ infosiswa.nisn }}</td>
              </tr>
              <tr>
                <td>TTL</td>
                <td>&nbsp;:&nbsp;</td>
                <td>{{ infosiswa.ttl }}</td>
              </tr>
              <tr>
                <td>KELAS</td>
                <td>&nbsp;:&nbsp;</td>
                <td>{{ infosiswa.kelas }}</td>
              </tr>
            </table>
          </v-card>
        </v-card>
      </v-col>
      <v-col class="stats-widget-v3">
        <v-card
          class="mx-auto"
          max-width="500"
        >
          <v-toolbar
            color="blue darken-3"
            dark
          >
            <v-app-bar-nav-icon></v-app-bar-nav-icon>

            <v-toolbar-title>JADWAL</v-toolbar-title>

            <v-spacer></v-spacer>
          </v-toolbar>

          <v-card
            class="mx-auto overflow-y-auto"
            height="300"
            max-width="500"
          >
            <v-list two-line>
              <v-list-item-group
                active-class="blue--text"
                multiple
              >
                <template v-for="(item, index) in items">
                  <v-list-item :key="item.title">
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title
                          class=" text-uppercase text-subtitle-2"
                          v-text="item.title"
                        ></v-list-item-title>
                        <v-list-item-title
                          class=" text-uppercase text-subtitle-2"
                          v-text="item.jurusan"
                        ></v-list-item-title>

                        <v-list-item-subtitle
                          class="black--text"
                          v-text="item.tanggal"
                        ></v-list-item-subtitle>

                        <v-list-item-subtitle v-text="item.jam"></v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-chip
                          small
                          :color="item.status ? `green` : `red`"
                          :disabled="!item.status"
                          class="white--text"
                          @click="openConfirmUjian(item.id)"
                        >{{ item.status ? "Buka" : "Tutup" }}</v-chip>
                      </v-list-item-action>
                    </template>
                  </v-list-item>

                  <v-divider
                    v-if="index < items.length - 1"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-card>
      </v-col>
      <v-col class="stats-widget-v3">
        <v-card
          :class="device.desktop ? `ml-7` : ``"
          max-width="500"
        >
          <v-toolbar
            color="blue darken-3"
            dark
          >
            <v-app-bar-nav-icon></v-app-bar-nav-icon>

            <v-toolbar-title>INFORMASI SEKOLAH</v-toolbar-title>

            <v-spacer></v-spacer>
          </v-toolbar>

          <v-card
            class="mx-auto overflow-y-auto"
            height="300"
            max-width="500"
          >
            <v-list two-line>
              <v-list-item-group
                v-model="selected"
                active-class="blue--text"
                multiple
              >
                <template v-for="(item, index) in infos">
                  <v-timeline
                    align-top
                    dense
                  >
                    <v-timeline-item
                      color="pink"
                      small
                    >
                      <v-row class="pt-1">
                        <v-col cols="12">
                          {{ item.created_at }}
                        </v-col>
                        <v-col cols="12">
                          <strong>{{ item.title }}</strong>
                          <div class="caption">
                            {{ item.body }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-timeline-item>
                  </v-timeline>

                  <v-divider
                    v-if="index < items.length - 1"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SectionTooltip from "../../../components/SectionTooltip";

export default {
  name: "dashboard-siswa",
  components: {
    SectionTooltip,
  },
  data: () => ({
    num: 1,
    headers: [
      { text: "#", value: "num" },
      {
        text: "TINGKAT",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "KETERANGAN", value: "description" },
      { text: "OPSI", value: "id" },
    ],

    search: null,
    jmlpegawai: 25,

    selected: [2],
    items: [],

    infosiswa: {},

    isCompeleted: false,

    infos: [],
  }),
  computed: {
    ...mapState([
      "page",
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
      "user",
    ]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/dashboard",
      title: "Dashboard",
      subtitle: "Berikut Gambaran Semua Data Aplikasi Anda",
      breadcrumbs: [
        {
          text: "Dashboard",
          disabled: true,
          href: "",
        },
      ],
    });

    this.fetchJadwal();
    this.fetchIsCompleted();
    this.fetchInfoSiswa();
    this.fetchInfoSekolah();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "fetchRecords",
      "postAddNew",
      "postEdit",
      "postUpdate",
      "postConfirmDelete",
    ]),

    openConfirmUjian: function (val) {
      this.$router.push({
        name: "konfirmasi-ujian",
        params: { ujian_id: val },
      });
    },
    fetchInfoSiswa: async function () {
      let { data } = await this.http.post("api-v2/info-siswa");

      this.infosiswa = data;
    },
    fetchJadwal: async function () {
      let { data } = await this.http.post("api-v2/jadwal");

      this.items = data;
    },
    fetchInfoSekolah: async function () {
      let { data } = await this.http.post("api-v2/info-sekolah");

      this.infos = data;
    },
    fetchIsCompleted: async function () {
      let {
        data: { status, message },
      } = await this.http.post("api-v2/siswa/check-biodata");

      this.isCompeleted = status;
    },
  },
};
</script>
