<template>
  <div :class="device.desktop ? `home pa-6 grey lighten-4`:`home pa-0 grey lighten-4`">
    <v-row>
      <v-col cols="12">
        <v-card class="animated animate__fadeInUp rounded-0">
          <v-card-title :class="`flex  `+  theme.color + ` lighten-2 white--text`">
            Formulir Biodata Siswa
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text>
            <v-row>
              <v-col :cols="device.desktop ?10:12">
                <v-col cols="12">
                  <v-text-field
                    label="Nomor Induk Kependudukan (NIK) *"
                    outlined
                    :color="theme.color"
                    dense
                    v-model="record.nik"
                    hide-details
                    maxlength="16"
                    minlength="16"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Nomor Kartu Keluarga (KK)*"
                    outlined
                    :color="theme.color"
                    dense
                    v-model="record.nokk"
                    hide-details
                    maxlength="16"
                    minlength="16"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Nomor Induk Siswa (NIS) *"
                    outlined
                    :color="theme.color"
                    dense
                    v-model="record.nis"
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Nomor Induk Siswa Nasional (NISN)"
                    outlined
                    :color="theme.color"
                    dense
                    v-model="record.nisn"
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Nama Lengkap *"
                    outlined
                    :color="theme.color"
                    dense
                    v-model="record.nama"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col :cols="device.mobile ? `12` : `8`">
                      <v-text-field
                        label="Tempat Lahir *"
                        outlined
                        :color="theme.color"
                        dense
                        v-model="record.tempat_lahir"
                        hide-details
                      ></v-text-field>
                    </v-col>

                    <v-col :cols="device.mobile ? `12` : `4`">
                      <v-text-field
                        label="Tanggal Lahir *"
                        outlined
                        :color="theme.color"
                        dense
                        v-model="record.tanggal_lahir"
                        type="date"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        label="Jenis Kelamin"
                        :color="theme.color"
                        outlined
                        hide-details
                        v-model="record.jenis_kelamin"
                        :items="gens"
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Nama Ibu Kandung"
                        outlined
                        :color="theme.color"
                        dense
                        v-model="record.nama_ibu"
                        :hide-details="device.desktop"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Asal Sekolah"
                        outlined
                        :color="theme.color"
                        dense
                        v-model="record.asal_sekolah"
                        hide-details
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-select
                        label="Provinsi"
                        :color="theme.color"
                        outlined
                        hide-details
                        v-model="record.province_id"
                        :items="provinsis"
                        dense
                        @change="fetchKabupatens"
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        label="Kabupaten/Kota"
                        :color="theme.color"
                        outlined
                        hide-details
                        v-model="record.regency_id"
                        :items="kabupatens"
                        dense
                        @change="fetchKecamatans"
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        label="Kecamatan"
                        :color="theme.color"
                        outlined
                        hide-details
                        v-model="record.district_id"
                        :items="kecamatans"
                        dense
                        @change="fetchDesas"
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        label="Desa"
                        :color="theme.color"
                        outlined
                        hide-details
                        v-model="record.village_id"
                        :items="desas"
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        label="Alamat"
                        :color="theme.color"
                        v-model="record.alamat"
                        outlined
                        dense
                        rows="3"
                        hide-details
                      >{{ record.alamat }}</v-textarea>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Nomor Telpon"
                        outlined
                        :color="theme.color"
                        dense
                        v-model="record.telp"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Email"
                        outlined
                        :color="theme.color"
                        dense
                        v-model="user.email"
                        hide-details
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

              </v-col>
              <v-col :cols="device.desktop ? 2:12">
                <v-col :cols="device.desktop ? `12` : `12`">
                  <v-img
                    :class="[theme.color + ' lighten-2 with-backdrop']"
                    :aspect-ratio="3 / 4"
                    :src="foto"
                    :lazy-src="foto"
                  >
                    <div
                      class="d-flex flex-column align-center justify-center user-select-none"
                      style="height: 100%;"
                    >
                      <div style="position: absolute; right: 8px; bottom: 8px; height: 36px; width: 36px;">
                        <v-btn
                          icon
                          dark
                          @click="uploadFoto"
                        >
                          <v-icon>photo_camera</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-img>
                  <center>Foto 3x4 Max: 2Mb</center>
                </v-col>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-action class="mt-5">
            <v-col class="text-right">
              <v-btn
                outlined
                :color="theme.color"
                @click="fetchUpdate"
              >SIMPAN</v-btn>
            </v-col>
          </v-card-action>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "perusahaan-index",
  data: () => ({
    foto: "/",
    record: {},
    gens: [
      { text: "Laki-laki", value: "L" },
      { text: "Perempuan", value: "P" },
    ],
    provinsis: [],
    kabupatens: [],
    kecamatans: [],
    desas: [],
  }),
  computed: {
    ...mapState([
      "theme",
      "http",
      "device",
      "records",
      "loading",
      "event",
      "snackbar",
      "user",
    ]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api-v2/biodata",
      pagination: false,
      key: "uuid",
      title: "BIODATA",
      subtitle: "Formulir Biodata Siswa",
      breadcrumbs: [
        {
          text: "Biodata",
          disabled: true,
          href: "",
        },
        {
          text: "Formulir Pegawai Baru",
          disabled: true,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: true,
        add: true,
        edit: true,
        delete: true,
        bulkdelete: false,
        print: false,
        export: false,
      },
    });
    this.fetchProvinsis();
    this.fetchRecord();
  },
  mounted() {},
  methods: {
    ...mapActions(["setPage", "assignFileBrowse", "getUserInfo"]),
    fetchRecord: async function () {
      let { data } = await this.http.get("api-v2/biodata");

      this.record = data;
      this.foto = this.record.path;
    },

    fetchUpdate: async function () {
      try {
        let {
          data: { status, message, data },
        } = await this.http.post(
          "api-v2/biodata/" + this.record.id + "?_method=PUT",
          this.record
        );

        if (!status) {
          this.snackbar.color = "red";
          this.snackbar.text = message;
          this.snackbar.state = true;
          return;
        }

        this.snackbar.color = this.theme.color;
        this.snackbar.text = message;
        this.snackbar.state = true;

        this.record = data;
        this.fetchRecord();
        this.getUserInfo();
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = error;
        this.snackbar.state = true;
      }
    },

    fetchProvinsis: async function () {
      let { data: data } = await this.http.post("api/provinsi-combo");
      this.provinsis = data;
    },

    fetchKabupatens: async function () {
      let { data: data } = await this.http.post(
        "api/kabupaten-combo",
        this.record
      );
      this.kabupatens = data;
    },
    fetchKecamatans: async function () {
      let { data: data } = await this.http.post(
        "api/kecamatan-combo",
        this.record
      );

      this.kecamatans = data;
    },
    fetchDesas: async function () {
      let { data: data } = await this.http.post("api/desa-combo", this.record);
      this.desas = data;
    },

    uploadFoto: function () {
      this.assignFileBrowse({
        fileType: [".jpg", ".jpeg", ".png"],
        query: {
          doctype: "biodata-siswa",
        },
        callback: (response) => {
          setTimeout(() => {
            this.foto = response.path;
            this.record.foto = response.name;
          }, 1000);
        },
      });
    },
  },
  watch: {
    "record.province_id": function () {
      this.fetchKabupatens();
    },
    "record.regency_id": function () {
      this.fetchKecamatans();
    },
    "record.district_id": function () {
      this.fetchDesas();
    },

    "reocrd.pendidikan_id": function () {
      this.fetchJurusan();
    },
  },
};
</script>

<style></style>
