<template>
  <v-container
    fluid
    class="pt-0 grid-list-xl"
  >
    <v-row class="pa-1">
      <v-col class="stats-widget-v3">
        <v-card
          class="mx-auto my-10"
          max-width="500"
        >
          <v-toolbar
            color="blue darken-3"
            dark
          >

            <v-toolbar-title>TERIMA KASIH</v-toolbar-title>

            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-title class="text-uppercase">{{ ujian.mapel }}</v-card-title>
          <v-row
            align="center"
            class="mx-0"
          >
            <div class="grey--text ml-4">Tanggal : {{ ujian.tanggal }}</div>
            <v-spacer></v-spacer>
            <div class="grey--text mr-4">
              Waktu:
              <v-chip
                small
                color="green"
              >{{ ujian.jam_mulai }}</v-chip> s.d
              <v-chip
                color="red"
                small
              >{{ ujian.jam_selesai }}</v-chip>
            </div>
          </v-row>
          <v-divider class="mx-4 my-6"></v-divider>
          <v-card-text>
            <div class="text-center text-h5 green--text ">
              Terima Kasih
            </div>
            <div class="text-center text-h6 green--text mb-10">
              Anda Telah Menyelesaikan Ujian
            </div>
          </v-card-text>
          <v-card-actions class="">
            <v-spacer> </v-spacer>
            <v-btn
              color="green lighten-2"
              text
              @click="postConfirm"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Sekolah",

  data: () => ({
    num: 1,
    headers: [
      { text: "#", value: "num" },
      {
        text: "TINGKAT",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "KETERANGAN", value: "description" },
      { text: "OPSI", value: "id" },
    ],

    search: null,

    token: null,

    ujian: {},
  }),
  computed: {
    ...mapState([
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
      "snackbar",
    ]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/dashboard",
    });

    this.fetchInfoSoal();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "fetchRecords",
      "postAddNew",
      "postEdit",
      "postUpdate",
      "postConfirmDelete",
    ]),
    test: function (val) {
      alert("oke" + val);
    },
    openConfirmUjian: function (val) {
      alert("Konfirmasi Ujian " + val);
    },
    fetchInfoSoal: async function () {
      let { data: data } = await this.http.post("api-v2/infosoal", {
        id: this.$route.params.ujian_id,
      });

      this.ujian = data;
    },
    postConfirm: async function () {
      this.$router.push({
        name: "dashboard",
      });
    },
  },
};
</script>
